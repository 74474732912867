import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBALpn1XRb1mfGoipya3JT0B7pvRPjw8nE',
  authDomain: 'wookees.firebaseapp.com',
  projectId: 'wookees',
  storageBucket: 'wookees.appspot.com',
  messagingSenderId: '192897022693',
  appId: '1:192897022693:web:6220a806cad101822f320c',
  measurementId: 'G-H9PGHCVE9P',
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true,
});

export const auth = firebase.auth();

export const storage = firebase.storage();
