import styles from './index.module.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckBox';
import { Paper } from '@material-ui/core';

export default function SuccessSnackBar(props) {
  const { respType } = props;
  return (
    <div className={styles.snakContainer}>
      {respType && respType === 'success' ? (
        <div className={styles.card}>
          <div className={styles.centerText}>
            <CheckCircleIcon
              style={{ color: '#4bb543' }}
              className={styles.snackIcon}
            />
          </div>
          <div>
            <h1 className={`${styles.centerText} ${styles.textMsg}`}>
              Form <br />
              submitted
            </h1>
          </div>
        </div>
      ) : respType === 'fail' ? (
        <Paper elevation={3} className={styles.card}>
          <div className={styles.centerText}>
            <HighlightOffIcon
              style={{ color: 'red' }}
              className={styles.snackIcon}
            />
          </div>
          <div>
            <h1 className={`${styles.centerText} ${styles.textMsg}`}>
              Submission <br />failed
            </h1>
          </div>
        </Paper>
      ) : null}
    </div>
  );
}
