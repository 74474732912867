import { db as Firestore } from '../helpers/Firestore';
import { COLLECTIONS } from '../config';

class CampaignService {
  async getCampaigns(organizationId) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).where(
      'organizationId',
      '==',
      organizationId
    );
    const snapshot = await ref.get();
    return snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  }

  async getCampaign(formId) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).doc(formId);
    const doc = await ref.get();
    return {
      id: doc.id,
      ...doc.data(),
    };
  }

  async getCampaignWithPageUrl(pageUrl) {
    const ref = Firestore.collection(COLLECTIONS.FORMS).where(
      'pageUrls',
      'array-contains',
      pageUrl
    );
    const snapshot = await ref.get();
    const doc = snapshot.docs[0];
    if (!doc) {
      console.error(
        `Unable to find a wookees campaign configured for ${pageUrl} this page`
      );
      return undefined;
    }

    return {
      id: doc.id,
      ...doc.data(),
    };
  }
}

export default new CampaignService();
