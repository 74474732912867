import VideoView from '../VideoView';
import { useEffect, useState } from 'react';
import IntroPopup from '../IntroPopup';
import FormService from '../../services/FormService';

const WOOKEES_WEBSITES = ['https://cdn.wookees.com', 'http://localhost:3000'];

export default function CampaignRoot() {
  const [play, setPlay] = useState();
  const [campaignData, setCampaignData] = useState();

  const openVideoView = () => {
    setPlay(true);
  };

  const closeVideoView = () => {
    setPlay(false);
  };

  const getCampaignId = () => {
    const segments = window.location.pathname.split('/');
    return segments.length >= 2 ? segments[1] : undefined;
  };

  useEffect(() => {
    async function loadFormDetails() {
      const loc = window.location;
      const campaignId = getCampaignId();

      let campaign;
      if (campaignId && WOOKEES_WEBSITES.includes(loc.origin)) {
        campaign = await FormService.getCampaign(campaignId);
      } else {
        const pageUrl = loc.origin + loc.pathname;
        campaign = await FormService.getCampaignWithPageUrl(pageUrl);
      }
      console.log(`Wookees:: Loading campaign ${campaign?.id}`);
      setCampaignData(campaign);
    }

    loadFormDetails().then();
  }, []);

  // const { REACT_APP_BE_ENDPOINT } = process.env;
  return (
    <div>
      {!campaignData ? (
        <div />
      ) : play ? (
        <VideoView campaignData={campaignData} onCloseView={closeVideoView} />
      ) : (
        <IntroPopup onClick={openVideoView} campaignData={campaignData} />
      )}
    </div>
  );
}
