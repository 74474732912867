import { useRef, useEffect, useState } from 'react';
import styles from './index.module.css';
import CloseIcon from '@material-ui/icons/Close';
import VideoOptionsView from '../VideoOptionsView/VideoOptionsView';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Loader from './Loader';
import VideoOption from '../VideoOptionsView/VideoOption';
import VideoFormView from '../VideoFormView/VideoFormView';
import SuccessSnackBar from './SuccessMsg';

function VideoView(props) {
  const { campaignData, onCloseView } = props;
  const videoRef = useRef();
  const [selectedVideo, setSelectedVideo] = useState();
  const [options, setOptions] = useState();
  const [playedTime, setPlayedTime] = useState('00:00');
  const [isLoading, setLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const [snackMsgType, setSnackMsgType] = useState();
  const [playBackSpeed, setPlayBackSpeed] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [formDetails, setFormDetails] = useState();

  useEffect(() => {
    const videos = campaignData.videos;
    if (!videos || !videos.length) {
      return;
    }
    const video = videos[0];
    setSelectedVideo(video);
  }, [campaignData]);

  useEffect(() => {
    async function startVideo() {
      if (!selectedVideo?.url) {
        return;
      }
      if (videoRef.current) {
        try {
          // Attempting to auto play the video
          await videoRef.current.play();
        } catch (error) {
          // Assuming audio auto play blocked by the browser and setting the video
          // into pause mode so that user can click a button to play the video
          setIsPaused(true);
        }
      }
    }

    setOptions(undefined);
    startVideo().then();
  }, [selectedVideo?.url]);

  const clearAllState = () => {
    setProgressCount(0);
    setPlayBackSpeed(0);
    setIsFullScreen(false);
    setPlayedTime('00:00');
    setIsPaused(false);
  };

  const videoEndCallback = () => {
    setIsFullScreen(false);
    const options = selectedVideo.options || [];

    // Closing video view if no options configured for current video
    if (!options.length) {
      onCloseView();
      return;
    }
    setIsPaused(true);
    setOptions(options);
  };
  const snackSetter = (type) => {
    setSnackMsgType(type);
    setTimeout(() => {
      setSnackMsgType('');
      handleFormClose();
    }, 2000);
  };
  const onOptionSelected = (option) => {
    clearAllState();
    // Closing video view if no target video configured for selected option
    if (option.targetVideo) {
      const video = (campaignData.videos || []).find(
        (video) => video.id === option.targetVideo
      );
      setSelectedVideo(video);
    } else if (option.targetForm) {
      const form = (campaignData.forms || []).find(
        (form) => form.id === option.targetForm
      );
      setFormDetails(form);
    } else if (option.targetUrl) {
      window.open(option.targetUrl, '_blank');
    } else {
      onCloseView();
      return;
    }
    setOptions(undefined);
  };
  const handlePlayPause = (event) => {
    if (videoRef.current.paused || videoRef.current.ended) {
      setIsPaused(false);
      videoRef.current.play();
    } else {
      setIsPaused(true);
      videoRef.current.pause();
    }
  };
  const updateProgressBar = () => {
    setPlayedTime(getFormattedTime(videoRef.current.currentTime));
    const percentage = Math.floor(
      (100 / videoRef.current.duration) * videoRef.current.currentTime
    );
    setProgressCount(percentage);
  };
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);
  const handleTimeChange = (e) => {
    const percent = e.nativeEvent.offsetX / videoRef.current.offsetWidth;
    setProgressCount(Math.floor(percent * 100));
    videoRef.current.currentTime = percent * videoRef.current.duration;
  };
  const getFormattedTime = (second) => {
    const seconds = Math.floor(second % 60);
    const minutes = Math.floor(second / 60);
    return !isNaN(seconds) || !isNaN(minutes)
      ? `${minutes < 10 ? '0' + minutes.toString() : minutes}:${
          seconds < 10 ? '0' + seconds.toString() : seconds
        }`
      : '00:00';
  };
  const updatePlayBackSpeed = () => {
    const updatedRate = (playBackSpeed + 1) % 3;
    setPlayBackSpeed(updatedRate);
    videoRef.current.playbackRate = 1 + updatedRate;
  };
  const handleFormClose = () => {
    setFormDetails(false);
    onCloseView();
  };
  return (
    <div
      className={`${
        isFullScreen
          ? styles.videoViewWrapperFullScreen
          : styles.videoViewWrapper
      }`}
    >
      <div
        className={`${styles.container} ${
          isFullScreen ? styles.bgBlack : null
        }`}
      >
        {selectedVideo && !formDetails && !snackMsgType && (
          <div className={styles.videoView}>
            {isLoading && (
              <div className={styles.loading}>
                <Loader />
              </div>
            )}
            {options && options.length > 3 ? (
              <VideoOptionsView
                options={options}
                onOptionSelected={onOptionSelected}
              />
            ) : (
              <>
                {isPaused && (
                  <PlayArrowRoundedIcon
                    className={styles.playBtn}
                    onClick={handlePlayPause}
                  />
                )}
                <div className={styles.controlsWrapper}>
                  <div className={styles.timeLine}>
                    {playedTime} /{' '}
                    {videoRef.current &&
                      getFormattedTime(videoRef.current.duration)}
                  </div>
                  <div
                    className={`${styles.playBackSpeed} ${
                      playBackSpeed > 0 ? styles.speedIncreased : null
                    }`}
                    onClick={updatePlayBackSpeed}
                  >
                    {(1 + 0.5 * playBackSpeed).toFixed(1)}x
                  </div>
                  {isFullScreen ? (
                    <FullscreenExitIcon
                      fontSize="large"
                      className={styles.fullScreenBtn}
                      onClick={toggleFullScreen}
                    />
                  ) : (
                    <FullscreenIcon
                      fontSize="large"
                      className={styles.fullScreenBtn}
                      onClick={toggleFullScreen}
                    />
                  )}
                </div>
                <progress
                  className={styles.videoProgressBar}
                  value={progressCount.toString()}
                  max="100"
                  onClick={handleTimeChange}
                >
                  {progressCount}%
                </progress>
                <video
                  ref={videoRef}
                  className={`${styles.videoView} ${
                    isFullScreen ? styles.fullVideoView : null
                  }`}
                  onEnded={videoEndCallback}
                  onLoadStart={() => {
                    setLoading(true);
                  }}
                  onLoadedData={() => {
                    setLoading(false);
                  }}
                  onTimeUpdate={updateProgressBar}
                  onClick={handlePlayPause}
                  src={selectedVideo.url || ''}
                  webkit-playsinline="true" /*Used to prevent going full screen video in iOS < 10*/
                  playsinline="true" /*Used to prevent going full screen video in iOS >= 10*/
                />
                {selectedVideo.options &&
                  selectedVideo.options.length <= 3 &&
                  !isLoading &&
                  !isFullScreen && (
                    <div className={styles.optionWrapper}>
                      {selectedVideo.options.map((option) => (
                        <VideoOption
                          key={option.id}
                          option={option}
                          optionClickHandler={onOptionSelected}
                        />
                      ))}
                    </div>
                  )}
              </>
            )}
          </div>
        )}
        {formDetails && (
          <VideoFormView
            campaignData={campaignData}
            formDetails={formDetails}
            handleFormClose={setFormDetails}
            responseSetter={snackSetter}
          />
        )}
        {snackMsgType && <SuccessSnackBar respType={snackMsgType} />}
        <a
          href="https://wookees.com"
          rel="noreferrer"
          className={`${styles.waterMark} ${
            isFullScreen ? styles.fullScreenWaterMark : null
          }`}
          target="_blank"
        >
          Powered by: <span className={styles.brandName}>Wookees</span>
        </a>
      </div>
      <CloseIcon
        htmlColor="#ED3952"
        className={`${
          isFullScreen ? styles.hideCloseIcon : styles.videoCloseButton
        }`}
        onClick={onCloseView}
      />
    </div>
  );
}

export default VideoView;
