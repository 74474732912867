import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import InjectedView from './components/InjectedView';

let wookeesRoot = document.getElementById('wookees');

const wookees = (
  <React.StrictMode>
    {wookeesRoot ? <App /> : <InjectedView />}
  </React.StrictMode>
);

if (!wookeesRoot) {
  const div = document.createElement('DIV');
  wookeesRoot = document.body.appendChild(div);
}

ReactDOM.render(wookees, wookeesRoot);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
