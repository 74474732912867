import styles from './style.module.css';

function IntroPopup(props) {
  const { onClick, campaignData } = props;

  const video =
    campaignData.videos && campaignData.videos.length
      ? campaignData.videos[0]
      : {};
  return (
    <div className={styles.container}>
      <div onClick={onClick} className={styles.introPopup}>
        <video className={styles.videoView} autoPlay muted loop>
          <source src={video.url || ''} />
        </video>
        <span className={styles.textSection}>Talk to me</span>
      </div>
    </div>
  );
}

export default IntroPopup;
