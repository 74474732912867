import { db as Firestore } from '../helpers/Firestore';
import { COLLECTIONS } from '../config';

class ContactFormService {
    async createResponse (data){
        const ref = Firestore.collection(COLLECTIONS.RESPONSE).doc();
        await ref.set(data);
        return ref.id;    
    }
}

export default new ContactFormService();