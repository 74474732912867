import styles from './index.module.css';
import VideoOption from './VideoOption';

function VideoOptionsView(props) {
  const { options, onOptionSelected } = props;

  return (
    <div className={styles.videoOptionsViewWrapper}>
      {options.map((option) => (
        <VideoOption
          key={option.id}
          option={option}
          optionClickHandler={onOptionSelected}
        />
      ))}
    </div>
  );
}

export default VideoOptionsView;
