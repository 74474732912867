import styles from './index.module.css';

export default function VideoOption(props){
    const {option, optionClickHandler} = props;
    return(
        <div key={option.id} className={styles.videoOptionsItemWrapper}>
        <div
          className={styles.videoOptionsItem}
          onClick={() => {
            optionClickHandler(option);
          }}
        >
          {option.title}
        </div>
      </div>
    )
}