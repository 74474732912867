import { useState } from 'react';
import styles from './index.module.css';
import firebase from 'firebase';
import { TextField, MenuItem, Select, InputLabel } from '@material-ui/core';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import ContactFormService from '../../services/ContactFormService';

export default function VideoFormView(props) {
  const { formDetails, handleFormClose, campaignData, responseSetter } = props;
  const [formFields, setFormFields] = useState({});

  const handleFormFieldChange = (e) =>
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  const handlePhoneNumberChange = (value) => {
    setFormFields({
      ...formFields,
      phoneNumber: value,
    });
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    let ele = document.getElementById('FORM_ID');
    let chk_status = ele.checkValidity();
    ele.reportValidity();

    if (chk_status) {
      try {
        const data = {
          ...formFields,
          organizationId: campaignData.organizationId,
          campaignId: campaignData.id,
          campaignName: campaignData.name,
          formId: formDetails.id,
          formName: formDetails.name,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        await ContactFormService.createResponse(data);
        responseSetter('success');
      } catch (err) {
        responseSetter('fail');
      }
      handleFormClose('');
    }
  };

  const getInputField = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <TextField
            key={field.name}
            variant="standard"
            className={styles.bottomMargin}
            placeholder={`${field.required ? '*' : ''}${field.name}`}
            value={formFields[field.name]}
            name={field.name}
            onChange={handleFormFieldChange}
            required={field.required}
          />
        );
      case 'email':
        return (
          <TextField
            key={field.name}
            variant="standard"
            className={styles.bottomMargin}
            placeholder={`${field.required ? '*' : ''}Your ${field.name}`}
            value={formFields[field.name]}
            name={field.name}
            type="email"
            onChange={handleFormFieldChange}
            required={field.required}
          />
        );
      case 'phone':
        return (
          <MaterialUiPhoneNumber
            key={field.name}
            className={styles.bottomMargin}
            name={field.name}
            value={formFields.phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={`${field.required ? '*' : ''}Your phone Number`}
            required={field.required}
            defaultCountry="sg"
          />
        );
      case 'select':
        return (
          <div key={field.name} className={styles.bottomMargin}>
            <InputLabel id={`${field.name}-label`}>{field.name}</InputLabel>
            <Select
              labelId={`${field.name}-label`}
              className={styles.selectBox}
              name={field.name}
              value={formFields[field.name]}
              onChange={handleFormFieldChange}
              label={field.name}
            >
              {field.values.map((value) => (
                <MenuItem key={value.value} value={value.value}>
                  {value.title}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      default:
        return;
    }
  };

  return (
    <form className={styles.formWrapper} id="FORM_ID">
      <div className={styles.formContent}>
        <div className={styles.formTitle}>{formDetails.name}</div>
        <div className={styles.formInputs}>
          {formDetails.fields.map((field) => getInputField(field))}
        </div>
      </div>
      <button
        type="submit"
        className={styles.formSubmitBtn}
        onClick={handleFormSubmission}
      >
        Submit
      </button>
    </form>
  );
}
