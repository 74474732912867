import styles from './index.module.css';

export default function Loader() {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.companyName}>Powered by</div>
      <div className={styles.createdBrand}>Wookees</div>
    </div>
  );
}
