import App from '../../App';
import styles from './index.module.css';

export default function InjectedView() {
  return (
    <div className={styles.injectedBottomRight}>
      <App />
    </div>
  );
}
